import React, {FC} from 'react';
import './Footer.scss'

const Footer: FC = () => {


    return (
        <footer className='footer'>
            <p className='footer_typography'>© 2023 | Designed & coded by Evgenii Stepanishin</p>
        </footer>
    );
};

export default Footer;